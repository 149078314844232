
import { Box, Button, Link } from '@mui/material';
import { createTheme } from '@mui/system';

const theme = createTheme({
    palette: {
      background: {
        paper: '#d2fe5a',
      },
      text: {
        primary: '#173A5E',
        secondary: '#d2fe5a',
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
      button: {
        primary: '#fff'
      }
    },
    opt: {
      text: {
        color: '#fff',
      }
    }
  });

export default function Success() {

    return(
      <Box style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{color: theme.opt.text.color, fontSize: 30, fontFamily: 'Oswald'}}>Транзакция прошла успешно</div>
        <Link href="/" style={{marginTop: 20}}>
            <Button 
                style={{backgroundColor:theme.palette.button.primary}}
                component="label"
                variant="text"> На главную </Button>
        </Link>
      </Box>
    )
}