
import { Box, Button, Link } from '@mui/material';
import { createTheme } from '@mui/system';

const theme = createTheme({
    palette: {
      background: {
        paper: '#d2fe5a',
      },
      text: {
        primary: '#173A5E',
        secondary: '#d2fe5a',
        white: '#fff'
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
      button: {
        primary: '#fff'
      }
    },
    opt: {
      text: {
        color: '#fff',
      }
    }
  });

export default function Success() {

    return(
      <Box style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        <div style={{color: theme.palette.text.white, fontSize: '30px'}}>
          Наши правила<br/>
        </div>
        <div style={{color: theme.palette.text.white, fontSize: '20px', marginTop: '20px'}}>
          1.  Запрещается использовать какие либо средства автоматизации для получения выгоды;<br/>
          2. Запрещается злоупотреблять системой, пользоваться багами приложения в корыстных целях;<br/>
          3. Запрещается оскорблять администрацию и других участников проекта;<br/>
          4. Запрещается разжигать ненависть и межнациональные конфликты.<br/>
        </div>
        <div style={{color: theme.palette.text.white, fontSize: '16px', marginTop: '10px'}}>
          Правила могут быть дополнены на усмотрение администрации, без предупреждения других участников.
        <br/>
        </div>
        <Link href="/" style={{marginTop: 20}}>
            <Button 
                style={{backgroundColor:theme.palette.button.primary}}
                component="label"
                variant="text"> На главную </Button>
        </Link>
      </Box>
    )
}